<template>
  <div class="box">
    <!-- 视频盒子 -->
    <div class="video-box">
      <video
        id="video"
        autoplay
        loop
        muted
        playsinline
        webkit-playsinline
        x5-playsinline
        x-webkit-airplay="allow"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
        x5-video-player-type="h5"
      >
        <source src="@/assets/video/aboutVideo.mp4" type="video/mp4" />
      </video>
      <div class="mobel-img">
        <img src="@/assets/imgs/aboutUs-mobel.jpg" />
      </div>
      <!-- <div class="video-text">
        <span>Jiachengbo Education (Beijing) Consulting Co., Ltd., established in 2008, is a well-known international education product and service provider in the industry. In July 2017, CCB became a member of boschle, a listed Education Group on the main board of the New York Stock Exchange.</span>
      </div> -->
      <!-- 副标题 -->
      <div class="sub-nav">
        <p @click="changeSub('1')" :class="subActive === '1' ? 'active-p' : ''">
          Company profile
        </p>
        <div class="line-box"></div>
        <p @click="changeSub('2')" :class="subActive === '2' ? 'active-p' : ''">
          Contact information
        </p>
      </div>
    </div>
    <!-- 公司简介盒子 -->
    <div v-show="subActive === '1'" class="Introduction-box w">
      <!-- <h1 class="pc-business-title">Jiacheng's Business</h1> -->
      <!-- <div class="mobile-business-title">Jiacheng's Business</div> -->
      <img class="Introduction-img" src="@/assets/imgs/networkImg.jpg" />
      <div class="introduce">
        <p>
          Can-Achieve（short for Bright Can-achieve）is a first rank education
          service provider founded in 2008. Since then, it has grown to be a
          leading organization in China which provides strategic solutions for
          overseas institutions in student recruitment, joint-venture programs,
          study tours, marketing events and related programs. With more than 300
          education professionals across China, out of which, one third of them
          have been with the company for more than 5 years.
        </p>
        <p>
          Can-Achieve has established a strong network of nearly 3000 active
          partner counselors to help the overseas institutional partners. Over
          the years, Can-Achieve has earned great recognitions from 3000
          institutions globally. Our team has been delivering top-notch services
          to prestigious universities and schools worldwide. Headquartered in
          Beijing, with the 20 offices in China and five international offices
          in Toronto, Vancouver, Los Angeles, Ho Chi Minh City and Bangkok.
        </p>
        <p>
          Can-Achieve are keen to identify the needs of our partners and create
          innovative strategies and service packages tailored to meet the
          demands of each partner. Each segment of our services is backed by a
          team of experts who possess rich experience in international
          education. We provide a wide range of services from agency
          representation, application processing and support, event management,
          online/offline training, institutional branding, to pathway programs,
          study tours, career development, expatriate teachers placement, etc.
          Besides, with the highly development the Internet we realized that
          Ganeration Z is not only the core of the Internet, but also the main
          group for international education. However, we influence Internet
          users' views of schools through local social media platforms, with
          multiple offline student recruitment events. We also offer efficient
          services including : provide digital media solution；set up program
          office；branding and planning online/offline events； By the
          philosophy shared among all Can-Achievers, which is bringing the best
          education to students, we only partner with institutions that uphold
          high education standards and quality.
        </p>
      </div>
    </div>
    <!-- 联系信息盒子 -->
    <div v-show="subActive === '2'" class="contact-box w">
      <div
        v-for="(item, index) in contactList"
        :key="index"
        class="contact-item"
      >
        <h1>{{ item.title }}</h1>
        <p v-show="item.phone">
          <i><img class="icon-img" src="@/assets/logos/qipao.png" /> Phone</i>
          <span>{{ item.phone }}</span>
        </p>
        <p v-show="item.fax">
          <i><img class="icon-img" src="@/assets/logos/dayin.png" /> Fax</i>
          <span>{{ item.fax }}</span>
        </p>
        <!-- 姓名 职位 -->
        <p v-show="item.name">
          <i><img class="icon-img" src="@/assets/logos/name.png" /> Name</i>
          <span>{{ item.name }}</span>
        </p>
        <!-- 姓名 职位 -->
        <p v-show="item.position">
          <i><img class="icon-img" src="@/assets/logos/position.png" /> Position</i>
          <span>{{ item.position }}</span>
        </p>
        
        <p v-show="item.email">
          <i><img class="icon-img" src="@/assets/logos/email.png" /> E-mail</i>
          <span>{{ item.email }}</span>
        </p>
        <p v-show="item.add">
          <i><img class="icon-img" src="@/assets/logos/dingwei.png" /> Add</i>
          <span>{{ item.add }}</span>
        </p>
        <img class="contentImg2" v-show="item.img" :src="item.img" alt="">
      </div>
      <!--  重新更新 加诚联系方式，按总部，海外（人员详情）列出    -->
      <!--      <div class="contact-item">
        <h1>Bright Can-Achieve, Beijing</h1>
        <p v-show="item.phone">
          <i><img class="icon-img" src="@/assets/logos/qipao.png"> Phone</i>
          <span>8610-5869.9445</span>
        </p>
        <p v-show="item.email">
          <i><img class="icon-img" src="@/assets/logos/email.png"> E-mail</i>
          <span>Marketing@can-achieve.com</span>
        </p>
      </div>
      <div class="contact-item">
        <h1>Bright Can-Achieve </h1>

      </div>-->
    </div>
    <div v-show="showTop" class="to-top" @click="toTop">
      <img src="@/assets/logos/top.png" />
      TOP
    </div>
    <gqIntroduction class="gqIntroduction" />
  </div>
</template>

<script>
import gqIntroduction from "@/components/gq-introduction.vue";
export default {
  components: {
    gqIntroduction,
  },
  data() {
    return {
      showTop: false,
      subActive: "1",
      contactList: [
        {
          title: "Bright Can-Achieve, Beijing",
          phone: "8610-5869.9445",
          fax: "8610-5869.4171",
          email: "Marketing@can-achieve.com",
          add: "802,Tower B,JianWai SOHO Office Building,No.39, Dongsanhuan Zhonglu Chaoyang District,Beijing,100022,China",
        },
        {
          title: "Bright Can-Achieve, Taiwan, China",
          phone: "886 0972010024",
          fax: "",
          email: "vincechen@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Telangana, India",
          phone: "91 9692059999",
          fax: "",
          email: "venkat@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Delhi, India",
          phone: "91 8527755772",
          fax: "",
          email: "premkumar@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Delhi, India",
          phone: "91 9911797240",
          fax: "",
          email: "parbinkaur@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve - North America",
          phone: "",
          fax: "",
          name: "Melody Xu",
          position: "BD Director",
          email: "melodyxu@can-achieve.com",
          add: "333 Seymour, Vancouver, BC, Canada V6B 5A6",
          img: require("@/assets/logos/Melody Xu.png"),
        },
        {
          title: "Bright Can-Achieve - Western USA",
          phone: "",
          fax: "",
          name: "Helene Lu",
          position: "Business Development Specialist",
          email: "helenelu@can-achieve.com",
          add: "530 Technology Drive Suite 100 Irvine, CA 926, USA",
          img: require("@/assets/logos/Helene_LU.jpg"),
        },
        {
          title: "Bright Can-Achieve - Western Canada",
          phone: "",
          fax: "",
          name: "Ansen Yin",
          position: "Marketing Specialist",
          email: "ansenyin@can-achieve.com",
          add: "333 Seymour, Vancouver, BC, Canada V6B 5A6",
          img: require("@/assets/logos/Ansen Yin.png"),
        },
        {
          title: "Bright Can-Achieve - Eastern Canada",
          phone: "",
          fax: "",
          name: "Shirley Wang",
          position: "Marketing Specialist",
          email: "shirleywang@can-achieve.com",
          add: "",
          img: require("@/assets/logos/Shirley Wang.png"),
        },
        {
          title: "Bright Can-Achieve, HCMC, Vietnam",
          phone: "84 973528807",
          fax: "",
          email: "peterle@can-achieve.com",
          add: "56 Nguyen Dinh Chieu, Da Kao, Distric 1, HCMC",
        },
        // {
        //   title: "Bright Can-Achieve, HCMC, Vietnam",
        //   phone: "84 907652168",
        //   fax: "",
        //   email: "amitrinh@can-achieve.com",
        //   add: "",
        // },
        {
          title: "Bright Can-Achieve, HCMC, Vietnam",
          phone: "84 0855563090",
          fax: "",
          email: "tomvu@can-achieve.com",
          add: "56 Nguyen Dinh Chieu, Da Kao, Distric 1, HCMC",
        },
        {
          title: "Bright Can-Achieve, HCMC, Vietnam",
          phone: "84 389971622",
          fax: "",
          email: "nguyetle@can-achieve.com",
          add: "56 Nguyen Dinh Chieu, Da Kao, Distric 1, HCMC",
        },
        {
          title: "Bright Can-Achieve, Hanoi, Vietnam",
          phone: "84 932202069",
          fax: "",
          email: "helennguyen@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Indonesia",
          phone: "84 973528807",
          fax: "",
          email: "Peterle@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Islamabad, Pakistan",
          phone: "92 3178301500",
          fax: "",
          email: "irfan.khan@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Nepal",
          phone: "91-9692059999",
          fax: "",
          email: "venkat@can-achieve.com",
          add: "",
        },
        {
          title: "Bright Can-Achieve, Hong Kong Office",
          phone: "+86 18565633890",
          fax: "",
          email: "shengangaoBD@can-achieve.com",
          add: "13/F, Chinachen Hollywood CTR, 1-13 Hollywood RD, Central, Hong Kong",
        },
      ],
    };
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    handleScroll() {
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.showTop = false;
      if (scroll > 100) this.showTop = true;
    },
    changeSub(id) {
      this.subActive = id;
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.subActive = this.$route.query.id;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route(to, from) {
      this.subActive = to.query.id;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    // subActive(newV, oldV) {
    //   this.active = newV ? newV : '1'
    // },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.w {
  width: 1440px !important;
  margin: 0 auto;
}
.video-box {
  position: relative;
  width: 100%;
  #video {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -999;
    /*加滤镜*/
    /*filter: blur(15px); //背景模糊设置 */
    /*-webkit-filter: grayscale(100%);*/
    /*filter:grayscale(100%); //背景灰度设置*/
  }
  source {
    // min-width: 100%;
    // min-height: 100%;
    height: 100%;
    width: 100%;
  }
  .mobel-img {
    display: none;
  }
  .video-text {
    box-sizing: border-box;
    position: absolute;
    bottom: 200px;
    width: 100%;
    padding: 0 187px;
    text-align: center;
    font-size: 18px;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 21px;
    color: #fff;
    opacity: 1;
    z-index: 1;
  }
  .sub-nav {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 172px;
    background: linear-gradient(270deg, #62818e 0%, #81ccd9 100%);
    z-index: 1;
    .line-box {
      position: relative;
      box-sizing: border-box;
      width: 29px;
      height: 49px;
      margin: 0 120px;
    }
    .line-box::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 24px;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      transform-origin: bottom center;
      transform: rotateZ(-60deg) scale(1.5);
      animation: slash 5s infinite ease;
    }
    p {
      box-sizing: border-box;
      padding: 17px 0;
      font-size: 36px;
      font-family: Helvetica;
      font-weight: normal;
      line-height: 30px;
      color: #ffffff;
      opacity: 1;
      cursor: pointer;
    }
    .active-p {
      font-style: oblique;
      border-bottom: 2px solid #fff;
    }
  }
}
.Introduction-box {
  // width: 100%;
  padding-top: 100px;
  padding-bottom: 64px;
  // .pc-business-title {
  //   margin: 60px 0;
  //   font-size: 90px;
  //   text-align: center;
  //   font-family: Helvetica;
  //   line-height: 98px;
  //   color: #333333;
  //   opacity: 1;
  // }
  // .mobile-business-title {
  //   display: none;
  // }
  .introduce {
    box-sizing: border-box;
    margin-left: 180px;
    margin-top: -353px;
    width: 1080px;
    background: #658a97;
    padding: 28px 18px 73px 31px;
    font-size: 18px;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 34px;
    color: #ffffff;
    opacity: 0.9;
    text-indent: 18px;
  }
  .Introduction-img {
    width: 806px;
    height: 649px;
  }
}
.contact-box {
  box-sizing: border-box;
  padding-top: 92px;
  padding-left: 175px;
  .contact-item {
    padding: 44px 0 56px;
    border-bottom: 1px solid #d8d8d8;
    position: relative;
    h1 {
      font-size: 54px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 30px;
      color: #333333;
      opacity: 1;
      margin-bottom: 32px;
    }
    p {
      display: flex;
      align-items: flex-start;
      i {
        display: flex;
        align-items: center;
        width: 80px;
        font-size: 18px;
        font-family: Helvetica;
        font-weight: normal;
        line-height: 41px;
        color: #959595;
        opacity: 1;
        margin-right: 20px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 7px;
          vertical-align: middle;
        }
      }
      span {
        flex: 1;
        font-size: 18px;
        font-family: Helvetica;
        font-weight: normal;
        line-height: 41px;
        color: #222222;
        opacity: 1;
      }
    }
  }
}
.to-top {
  display: none;
}
@media only screen and (max-width: 768px) {
  .w {
    width: 100% !important;
  }
  .box {
    margin: 0;
    padding-bottom: 1rem;
    width: 10rem;
  }
  .video-box {
    #video {
      display: none;
    }
    .mobel-img {
      display: block;
      width: 100%;
      height: 4.43rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .video-text {
      display: none;
    }
    .sub-nav {
      position: relative;
      height: 1.47rem;
      .line-box {
        display: none;
      }
      p {
        margin: 0 0.35rem;
        padding: 0.2rem 0.29rem;
        border-radius: 0.05rem;
        font-size: 0.37rem;
        line-height: 0.19rem;
        color: #ffffff;
        opacity: 1;
      }
      .active-p {
        background: #fff;
        font-style: normal;
        color: #62818e;
        border: none;
      }
    }
  }
  .Introduction-box {
    padding: 0;
    padding-top: 1rem;
    .mobile-business-title {
      margin: 0.4rem 0;
      width: 100%;
      text-align: center;
      display: block;
      font-size: 0.8rem;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 1.47rem;
      color: #333333;
      opacity: 1;
    }
    .pc-business-title {
      display: none;
    }
    .introduce {
      position: relative;
      top: -0.75rem;
      left: 0;
      width: 9.47rem;
      margin: 0 auto 0;
      background: #658a97;
      padding: 0.5rem;
      font-size: 0.37rem;
      line-height: 0.53rem;
      opacity: 1;
    }
    .Introduction-img {
      width: 100%;
      height: 8.08rem;
    }
  }
  .contact-box {
    box-sizing: border-box;
    padding: 0.83rem 0.25rem 0;
    .contact-item {
      padding: 0 0 0.69rem;
      border: none;
      h1 {
        font-size: 0.64rem;
        line-height: 0.75rem;
        margin-bottom: 0.4rem;
      }
      p {
        i {
          width: 2rem;
          font-size: 0.32rem;
          line-height: 0.91rem;
          margin-right: 0.25rem;
          img {
            width: 0.54rem;
            height: 0.54rem;
            margin-right: 0.35rem;
          }
        }
        span {
          padding-top: 0.15rem;
          font-size: 0.32rem;
          line-height: 0.6rem;
        }
      }
    }
  }
  .gqIntroduction {
    display: none;
  }
  .to-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0.53rem;
    right: 0.56rem;
    width: 1.33rem;
    height: 1.33rem;
    border-radius: 50%;
    background: #6f9fac;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0.35rem;
    color: #ffffff;
    img {
      width: 0.54rem;
      height: 0.54rem;
    }
  }
}
.contentImg{
  position: absolute;
  right: 0%;
  top: 0;
  top: 7%;
  width: 16%;
}
.contentImg2{
  position: absolute;
  right: 0%;
  top: 0;
  top: 15%;
  width: 16%;
}
</style>